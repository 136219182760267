/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import App from 'next/app';
import PropTypes from 'prop-types';
import { initAirbrake } from 'config/airbrake';
import { serverFetch } from 'utils/serverFetch';
import Head from 'next/head';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'config/theme';
import { appWithTranslation, Router } from 'config/i18n';
import GlobalStyles from 'components/shared/GlobalStyles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CookiesProvider } from 'react-cookie';
import devRoutes from 'utils/devRoutes';
import useRouterScroll from 'hooks/useRouterScroll';
import MomentUtils from '@date-io/moment';
// Set Logging Level based on passed ENV Variable
import logger from 'loglevel';
import serverLogger from 'utils/serverLogger';
import dynamic from 'next/dynamic';
import Layout from 'components/shared/Layout';

import axios, { API } from 'config/axios';
import getQueryCSR from 'common/utils/getQueryCSR';

import favicon from '../public/imgs/es/favicon.ico';

const LoadingSpinner = dynamic(() => import('components/shared/LoadingSpinner'), { ssr: false });

const Error = dynamic(() => import('components/shared/Error'), { ssr: false });
// Load ENV Variables
const { envVars } = require('next/config').default().publicRuntimeConfig;

const { GTM_CODE, API_PROTOCOL, EN_DOMAIN } = envVars;

logger.setDefaultLevel(envVars.LOG_LEVEL || 'error');

initAirbrake();
const MyApp = (props) => {
  serverLogger.debug('---[Trace] Inside App');
  const { Component, pageProps, err } = props;
  const {
    pendingOnLayout = true, ssrRender, ssoPage, headLinks,
  } = pageProps;
  // const eagleShare = Component.layoutOptions && Component.layoutOptions.eagleShare;
  useRouterScroll();
  const [layoutDataState, setLayoutDataState] = useState(pageProps.layoutData);
  const [layoutError, setLayoutError] = useState(false);
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  const getUserData = () => axios.get(API.me);
  const getLayoutData = () => axios.get(API.layout);
  const signViaAccessToken = () => {
    const query = getQueryCSR();
    axios
      .post(API.users.signViaAccessToken, {
        access_token: query.access_token,
      })
      .finally(() => {
        window.location.href = query.return_url;
      });
  };
  useEffect(() => {
    if (process.browser && !pageProps.ssrRender) {
      if (ssoPage) {
        signViaAccessToken();
      } else {
        Promise.all([getLayoutData(), getUserData()])
          .then((results) => {
            setLayoutDataState({
              ...results[0].data,
              ...results[1].data,
            });
          })
          .catch(async () => {
            setLayoutError(true);
          });
      }
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     process.browser
  //     && pageProps.requireAuth
  //     && ((layoutDataState && !layoutDataState.current_user))
  //   ) {
  //     Router.push(`/users/sign_in?return_url=${window.location.href}`);
  //   }
  // });
  useEffect(() => {
    // Listen for page changes after a navigation or when the query changes
    Router.events.on('routeChangeComplete', () => {
      window.ga('send', 'pageview', window.location.pathname + window.location.search);
    });
    return () => {
      Router.events.off('routeChangeComplete', () => {
        window.ga('send', 'pageview', window.location.pathname + window.location.search);
      });
    };
  }, [Router.events]);
  if (ssoPage) return null;
  return (
    <>
      <Head>
        <title>EagleShare</title>
        {/* change order affect the zoom in for ios */}
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta
          name="google-site-verification"
          content="6QXZUvx9xeE8GaAn96m1dsuNUUiTGNVbhREXUxd6nLg"
        />
        <meta property="og:image" content="/imgs/es/og_es_logo.jpg" />
        <meta charSet="utf-8" />
        <link rel="icon" href={favicon.src} />
      </Head>
      {!pageProps.ssrRender && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${GTM_CODE}`}
            height={0}
            title="gtag"
            width={0}
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      )}
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <CookiesProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Layout
              serverData={layoutDataState}
              requireAuth={pageProps.requireAuth}
              er={pageProps.er}
              pageIdentifier={pageProps.pageIdentifier}
              ssrRender={pageProps.ssrRender}
              deviceType={pageProps.deviceType}
              headLinks={headLinks}
              suppressSpinner={pageProps.suppressSpinner || false}
            >
              {!layoutError ? (
                <>
                  {!pendingOnLayout || Object.keys(layoutDataState).length > 0 ? (
                    <Component {...pageProps} err={err} />
                  ) : (
                    <div style={{ minHeight: '60vh' }}>
                      <LoadingSpinner open er={false} />
                    </div>
                  )}
                </>
              ) : (
                <Error statusCode={500} />
              )}
            </Layout>
          </MuiPickersUtilsProvider>
        </CookiesProvider>
      </ThemeProvider>
    </>
  );
};

MyApp.getInitialProps = async (appContext) => {
  // serverLogger.debug(`######------------> New Request ${appContext.ctx.req.url}`);
  serverLogger.debug('---[Trace] Inside App GetInitialProps');
  const appProps = await App.getInitialProps(appContext);
  if (appContext?.ctx?.req?.headers?.host === 'www-origin.eagleshare.com') {
    appContext.ctx.res.writeHead(301, {
      Location: `${API_PROTOCOL}://${EN_DOMAIN}${appContext.ctx.req.url}`,
    });
    appContext.ctx.res.end();
    return {
      pageProps: {
        ...appProps.pageProps,
      },
    };
  }
  // Prevent development routes to be accessed on production
  if (envVars.ENVIRONMENT == 'production' && devRoutes.indexOf(appContext.ctx.pathname) !== -1) {
    if (!process.browser) {
      appContext.ctx.res.statusCode = 503;
      appContext.ctx.res.end();
    } else {
      Router.push('/');
    }
  }
  if (!process.browser) {
    if (appContext.ctx.req.method === 'POST') {
      appContext.ctx.res.statusCode = 503;
      appContext.ctx.res.end();
    }
  }
  const { ssrRender } = appProps.pageProps;

  let layoutData = {};
  if (appProps.pageProps && appProps.pageProps.statusCode !== 503) {
    if (!process.browser && appContext.ctx.pathname != '/_error') {
      if (ssrRender) {
        const { success, data } = await serverFetch(appContext.ctx, API.layout);

        if (success && data) layoutData = { ...data, authenticity_token: null };
        else {
          appContext.ctx.res.statusCode = 500;

          throw new Error('Rendering halted because we failed to load layout data');
        }
      }
    }
  }

  return {
    pageProps: {
      ...appProps.pageProps,
      namespacesRequired: [
        // Add layout namespaces for all the pages
        'fe_es_layout',
        'layout',
        'routes',
        'fe_errors',
        'eaglerider_information',
        'fe_eaglerider_information',
        'rentals',
        'fe_es_commissionBanner',
        ...(appProps.pageProps.namespacesRequired || []),
      ],
      layoutData,
      ssrRender,
    },
  };
};
export default appWithTranslation(MyApp);

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
};
